import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import BotForm from './components/BotForm';
import BotPage from './components/BotPage';
import axios from 'axios';
import './App.css';

const App = () => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Verify token validity and handle redirects if necessary
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/verify-token`, {
        headers: {
          'x-auth-token': token
        }
      }).catch(() => {
        localStorage.removeItem('token');
        window.location.href = '/login';
      });
    }
  }, []);

  const PrivateRoute = ({ element, ...rest }) => {
    const token = localStorage.getItem('token');
    return token ? element : <Navigate to="/login" />;
  };

  const PublicRoute = ({ element, ...rest }) => {
    // only for Login and Sign up
    const token = localStorage.getItem('token');
    return token ? <Navigate to="/dashboard" /> : element;
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/signup" element={<PublicRoute element={<SignUp />} />} />
          <Route path="/login" element={<PublicRoute element={<Login />} />} />
          <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
          <Route path="/create-bot" element={<PrivateRoute element={<BotForm />} />} />
          <Route path="/edit-bot/:id" element={<PrivateRoute element={<BotForm />} />} />
          <Route path="/page/:slug" element={<BotPage />}/>
        </Routes>
      </div>
    </Router>
  );
};

export default App;
