import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-container">
      <header className="landing-header">
        <h1>Welcome to Magic bots</h1>
        <p>Create and manage custom chatbots for your small business.</p>
        <img width={400} height={400} src={`${process.env.REACT_APP_API_BASE_URL}/images/chatbot.webp`}/>
        <div className="cta-buttons">
            <button className="signup-btn" onClick={() => navigate('/signup')}>Sign Up</button>
            <button className="login-btn" onClick={() => navigate('/login')}>Login</button>
        </div>
      </header>
      <section className="features">
        <h2>Features</h2>
        <ul>
          <li>Easy-to-use interface</li>
          <li>Customizable chatbot appearance</li>
          <li>Manage multiple chatbots</li>
          <li>Features your bot anywhere</li>
        </ul>
      </section>
    </div>
  );
};

export default LandingPage;
