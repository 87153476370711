import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './BotPage.css'; // Import the custom styles

const BotPage = () => {
  const { slug } = useParams();
  const [bot, setBot] = useState(null);

  useEffect(() => {
    const fetchBot = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/bots/slug/${slug}`);
        setBot(res.data);
      } catch (err) {
        console.error(err.response.data);
      }
    };
    fetchBot();
  }, [slug]);

  if (!bot) return <div>Loading...</div>;

  const { name, description, link, customLinks, backgroundColor, buttonColor, fontFamily, fontColor } = bot;

  const pageStyle = {
    backgroundColor,
    fontFamily,
    color: fontColor
  };

  const buttonStyle = {
    backgroundColor: buttonColor,
    color: '#fff'
  };

  return (
    <div className="bot-page" style={pageStyle}>
      <div className="bot-content">
        <h1>{name}</h1>
        <p>{description}</p>
        <a href={link} className="bot-button" style={buttonStyle}>Visit Bot</a>
        <div className="custom-links">
          {customLinks.map((customLink, index) => (
            <a key={index} href={customLink.link} className="custom-link" style={buttonStyle}>
              {customLink.label}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BotPage;
