import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faCopy } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css';

const Dashboard = () => {
  const [bots, setBots] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBots = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/bots`, {
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });
        setBots(res.data);
      } catch (err) {
        console.error(err.response.data);
      }
    };

    fetchBots();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this bot?')) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/bots/${id}`, {
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });
        setBots(bots.filter(bot => bot._id !== id));
      } catch (err) {
        console.error(err.response.data);
      }
    }
  };

  const handleEdit = (id) => {
    navigate(`/edit-bot/${id}`);
  };

  const handleCopyScriptTag = (id) => {
    const scriptTag = `<script src="${window.location.origin}/magic-chatbot-widget/${id}"></script>`;
    navigator.clipboard.writeText(scriptTag).then(() => {
      alert('Script tag copied to clipboard. Insert it inside the <head> tag of your website.');
    }).catch(err => {
      console.error('Failed to copy script tag', err);
    });
  };

  return (
    <div className="dashboard-container">
      <h1>Your Bots</h1>
      <button onClick={() => navigate('/create-bot')} className="create-bot-btn">+ Create Bot</button>
      <div className="bots-list">
        {bots.map(bot => (
          <div key={bot._id} className="bot-card">
            {bot.profilePicture && (
              <img src={`${process.env.REACT_APP_API_BASE_URL}${bot.profilePicture}`} alt={bot.name} className="bot-picture" />
            )}
            <h2>{bot.name}</h2>
            <p>{bot.description}</p>
            <p>{bot.prompt}</p>
            <a href={bot.link} target="_blank" rel="noopener noreferrer">Visit Bot</a>
            <br />
            <a href={`/page/${bot.slug}`} target="_blank" rel="noopener noreferrer">View Bot Page</a>
            <div className="custom-links">
              {bot.customLinks.map(link => (
                <div key={link._id}>
                  <span>{link.label}: </span>
                  <a href={link.link} target="_blank" rel="noopener noreferrer">{link.link}</a>
                </div>
              ))}
            </div>
            <button className="copy-script-btn" onClick={() => handleCopyScriptTag(bot._id)}>
              <FontAwesomeIcon icon={faCopy} /> Copy Script Tag
            </button>
            <div className="bot-actions">
              <button className="edit-btn" onClick={() => handleEdit(bot._id)}>Edit Bot</button>
              <button className="delete-btn" onClick={() => handleDelete(bot._id)}>
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
