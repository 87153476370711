import React from 'react';
import './ErrorBox.css';

const ErrorBox = ({ message }) => (
  <div className="error-box">
    <p>{message}</p>
  </div>
);

export default ErrorBox;
