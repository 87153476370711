import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './BotForm.css';

const fontFamilies = [
  'Arial, sans-serif', 'Verdana, sans-serif', 'Helvetica, sans-serif', 'Tahoma, sans-serif',
  'Trebuchet MS, sans-serif', 'Times New Roman, serif', 'Georgia, serif', 'Garamond, serif',
  'Courier New, monospace', 'Brush Script MT, cursive', 'Impact, sans-serif', 'Lucida Sans, sans-serif',
  'Lucida Grande, sans-serif', 'Palatino, serif', 'Century Gothic, sans-serif', 'Arial Black, sans-serif',
  'Gill Sans, sans-serif', 'Franklin Gothic Medium, sans-serif', 'Lucida Console, monospace',
  'Segoe UI, sans-serif', 'Roboto, sans-serif', 'Open Sans, sans-serif', 'Lato, sans-serif',
  'Montserrat, sans-serif', 'Oswald, sans-serif', 'Raleway, sans-serif', 'Slabo 27px, serif',
  'PT Sans, sans-serif', 'Playfair Display, serif', 'Merriweather, serif', 'Fira Sans, sans-serif',
  'Oxygen, sans-serif', 'Nunito, sans-serif', 'Cabin, sans-serif', 'Droid Sans, sans-serif',
  'Ubuntu, sans-serif', 'Bitter, serif', 'Mukta, sans-serif', 'Hind, sans-serif', 'Quicksand, sans-serif',
  'Work Sans, sans-serif', 'Rubik, sans-serif', 'Karla, sans-serif', 'Inconsolata, monospace',
  'Josefin Sans, sans-serif', 'Varela Round, sans-serif', 'Indie Flower, cursive', 'Titillium Web, sans-serif',
  'Play, sans-serif'
];

const BotForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    prompt: '',
    link: '',
    customLinks: [{ label: '', link: '' }],
    backgroundColor: '#ffffff',
    buttonColor: '#007bff',
    fontFamily: 'Arial, sans-serif',
    fontColor: '#000000',
    alignment: 'Bottom Right',
    profilePicture: null
  });
  const [isEditing, setIsEditing] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchBot = async () => {
        try {
          const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/bots/${id}`, {
            headers: {
              'x-auth-token': localStorage.getItem('token')
            }
          });
          setFormData({
            ...res.data,
            customLinks: res.data.customLinks.length > 0 ? res.data.customLinks : [{ label: '', link: '' }]
          });
          setIsEditing(true);
        } catch (err) {
          console.error(err.response.data);
        }
      };
      fetchBot();
      previewBot(id);
    }
  }, [id]);

  const { name, description, prompt, link, customLinks, backgroundColor, buttonColor, fontFamily, fontColor, alignment, profilePicture } = formData;

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onCustomLinkChange = (index, field, value) => {
    const newCustomLinks = customLinks.map((customLink, i) => (i === index ? { ...customLink, [field]: value } : customLink));
    setFormData({ ...formData, customLinks: newCustomLinks });
  };

  const addCustomLink = () => {
    setFormData({ ...formData, customLinks: [...customLinks, { label: '', link: '' }] });
  };

  const removeCustomLink = index => {
    const newCustomLinks = customLinks.filter((_, i) => i !== index);
    setFormData({ ...formData, customLinks: newCustomLinks });
  };

  const onFileChange = e => {
    setFormData({ ...formData, profilePicture: e.target.files[0] });
  };

  const onSubmit = async e => {
    e.preventDefault();
    const botData = new FormData();
    botData.append('name', name);
    botData.append('description', description);
    botData.append('prompt', prompt);
    botData.append('link', link);
    botData.append('customLinks', JSON.stringify(customLinks));
    botData.append('backgroundColor', backgroundColor);
    botData.append('buttonColor', buttonColor);
    botData.append('fontFamily', fontFamily);
    botData.append('fontColor', fontColor);
    botData.append('alignment', alignment);
    if (profilePicture) {
      botData.append('profilePicture', profilePicture);
    }

    try {
      const config = { headers: { 'Content-Type': 'multipart/form-data', 'x-auth-token': localStorage.getItem('token') } };
      if (isEditing) {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/bots/${id}`, botData, config);
      } else {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/bots`, botData, config);
      }
      navigate('/dashboard');
      previewBot(id);
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <div className="container">
      { id ? <script src={`${process.env.REACT_APP_API_BASE_URL}/chatbot-widget/${id}`}></script> : null }
      <h1>{isEditing ? 'Edit Bot' : 'Create Bot'}</h1>
      <form onSubmit={e => onSubmit(e)}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" placeholder="Name" name="name" value={name} onChange={e => onChange(e)} required />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea id="description" placeholder="Description" name="description" value={description} onChange={e => onChange(e)} />
        </div>
        <div className="form-group">
          <label htmlFor="prompt">Prompt:</label>
          <textarea id="prompt" placeholder="Prompt" name="prompt" value={prompt} onChange={e => onChange(e)} />
        </div>
        <div className="form-group">
          <label htmlFor="link">Link:</label>
          <input type="text" id="link" placeholder="Link" name="link" value={link} onChange={e => onChange(e)} />
        </div>
        {customLinks.map((customLink, index) => (
          <div key={index} className="custom-link-group">
            <div className="form-group">
              <label htmlFor={`customLinkLabel${index}`}>Custom Link Label:</label>
              <input
                type="text"
                id={`customLinkLabel${index}`}
                placeholder="Custom Link Label"
                value={customLink.label}
                onChange={e => onCustomLinkChange(index, 'label', e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor={`customLink${index}`}>Custom Link:</label>
              <input
                type="text"
                id={`customLink${index}`}
                placeholder="Custom Link"
                value={customLink.link}
                onChange={e => onCustomLinkChange(index, 'link', e.target.value)}
              />
            </div>
          </div>
        ))}
        <div className="form-group add-remove-buttons">
          <button type="button" className="add-link-btn" onClick={addCustomLink}>Add Custom Link</button>
          <button type="button" className="remove-link-btn" onClick={() => removeCustomLink(customLinks.length - 1)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <label htmlFor="profilePicture">Profile Picture:</label>
          <input type="file" id="profilePicture" name="profilePicture" onChange={onFileChange} />
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <label htmlFor="backgroundColor">Background Color:</label>
          <input type="color" id="backgroundColor" name="backgroundColor" value={backgroundColor} onChange={e => onChange(e)} style={{ backgroundColor: backgroundColor }} />
        </div>
        <div className="form-group">
          <label htmlFor="buttonColor">Button Color:</label>
          <input type="color" id="buttonColor" name="buttonColor" value={buttonColor} onChange={e => onChange(e)} style={{ backgroundColor: buttonColor }} />
        </div>
        <div className="form-group">
          <label htmlFor="fontFamily">Font Family:</label>
          <select id="fontFamily" name="fontFamily" value={fontFamily} onChange={e => onChange(e)} style={{ fontFamily }}>
            {fontFamilies.map((font, index) => (
              <option key={index} value={font} style={{ fontFamily: font }}>{font}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="fontColor">Font Color:</label>
          <input type="color" id="fontColor" name="fontColor" value={fontColor} onChange={e => onChange(e)} style={{ backgroundColor: fontColor }} />
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <label htmlFor="alignment">Alignment:</label>
          <select id="alignment" name="alignment" value={alignment} onChange={e => onChange(e)}>
            <option value="Bottom Right">Bottom Right</option>
            <option value="Bottom Left">Bottom Left</option>
            <option value="Top Right">Top Right</option>
            <option value="Top Left">Top Left</option>
          </select>
        </div>
        <button type="submit">{isEditing ? 'Update Bot' : 'Create Bot'}</button>
      </form>
    </div>
  );
};

function previewBot(id) {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const apiUrl = `${baseUrl}/api/bots/${id}`;
    let element = document.getElementById('magic-chatbot-widget');
    if (element) {
        element.remove();
    }
    
    fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
            const {
                name,
                description,
                link,
                customLinks,
                backgroundColor,
                buttonColor,
                fontFamily,
                fontColor,
                alignment,
                profilePicture
            } = data;

            const widget = document.createElement('div');
            widget.id = 'magic-chatbot-widget';
            widget.style.position = 'fixed';
            widget.style[alignment.split(" ")[0].toLowerCase()] = '20px';
            widget.style[alignment.split(" ")[1].toLowerCase()] = '20px';
            widget.style.width = '60px';
            widget.style.height = '60px';
            widget.style.borderRadius = '50%';
            widget.style.backgroundColor = buttonColor;
            widget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
            widget.style.cursor = 'pointer';
            widget.style.display = 'flex';
            widget.style.alignItems = 'center';
            widget.style.justifyContent = 'center';
            widget.style.color = '#fff';
            widget.style.fontSize = '30px';
            widget.style.zIndex = '1000';

            const icon = document.createElement('div');
            icon.id = 'chatbot-icon';
            icon.innerText = '💬';
            widget.appendChild(icon);

            const menu = document.createElement('div');
            menu.id = 'chatbot-menu';
            menu.style.position = 'absolute';

            if (alignment == "Bottom Right") {
              menu.style.bottom = '80px';
              menu.style.right = '0';
            } else if (alignment == "Bottom Left") {
              menu.style.bottom = '80px';
              menu.style.left = '0px';
            } else if (alignment == "Top Right") {
              menu.style.top = '80px';
              menu.style.right = '0px';
            } else if (alignment == "Top Left") {
              menu.style.top = '80px';
              menu.style.left = '0px';
            }

            menu.style.width = '300px';
            menu.style.backgroundColor = backgroundColor;
            menu.style.borderRadius = '10px';
            menu.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
            menu.style.padding = '20px';
            menu.style.zIndex = '1000';
            menu.style.display = 'none';

            const header = document.createElement('div');
            header.id = 'chatbot-header';
            header.style.display = 'flex';
            header.style.alignItems = 'center';
            header.style.marginBottom = '20px';

            const profileImg = document.createElement('img');
            profileImg.src = baseUrl + profilePicture;
            profileImg.alt = 'Profile Picture';
            profileImg.id = 'chatbot-profile-picture';
            profileImg.style.width = '50px';
            profileImg.style.height = '50px';
            profileImg.style.borderRadius = '50%';
            profileImg.style.marginRight = '10px';

            const desc = document.createElement('p');
            desc.id = 'chatbot-description';
            desc.innerText = description;
            desc.style.flexGrow = '1';
            desc.style.fontSize = '14px';
            desc.style.color = fontColor;

            header.appendChild(profileImg);
            header.appendChild(desc);

            const buttons = document.createElement('div');
            buttons.id = 'chatbot-buttons';
            buttons.style.display = 'flex';
            buttons.style.flexDirection = 'column';

            const startChatButton = document.createElement('button');
            startChatButton.className = 'chatbot-button';
            startChatButton.innerText = 'Start Chat';
            startChatButton.style.backgroundColor = buttonColor;
            startChatButton.style.color = '#fff';
            startChatButton.style.border = 'none';
            startChatButton.style.borderRadius = '5px';
            startChatButton.style.padding = '10px';
            startChatButton.style.marginTop = '5px';
            startChatButton.style.cursor = 'pointer';
            startChatButton.style.fontSize = '14px';
            startChatButton.style.transition = 'background-color 0.3s ease';
            startChatButton.onclick = () => window.open(link, '_blank');
            startChatButton.onmouseover = () => startChatButton.style.backgroundColor = darkenColor(buttonColor);
            startChatButton.onmouseout = () => startChatButton.style.backgroundColor = buttonColor;

            buttons.appendChild(startChatButton);

            customLinks.forEach(customLink => {
                const button = document.createElement('button');
                button.className = 'chatbot-button';
                button.innerText = customLink.label;
                button.style.backgroundColor = buttonColor;
                button.style.color = '#fff';
                button.style.border = 'none';
                button.style.borderRadius = '5px';
                button.style.padding = '10px';
                button.style.marginTop = '5px';
                button.style.cursor = 'pointer';
                button.style.fontSize = '14px';
                button.style.transition = 'background-color 0.3s ease';
                button.onclick = () => window.open(customLink.link, '_blank');
                button.onmouseover = () => button.style.backgroundColor = darkenColor(buttonColor);
                button.onmouseout = () => button.style.backgroundColor = buttonColor;

                buttons.appendChild(button);
            });

            menu.appendChild(header);
            menu.appendChild(buttons);

            widget.appendChild(menu);

            widget.addEventListener('click', function() {
                menu.style.display = menu.style.display === 'none' ? 'block' : 'none';
            });

            document.body.insertBefore(widget, document.body.firstChild);
        });

    function darkenColor(color) {
        const amount = -30;
        return '#' + color.replace(/^#/, '').replace(/../g, color =>
            ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
        );
    }
}

export default BotForm;
